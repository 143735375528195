import React, { useMemo } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { DateTimeFormatter } from '@wix/bookings-date-time';
import { BenefitWithPlanInfo } from '@wix/ambassador-pricing-plans-v2-benefit/types';
import { PaidPlans } from '@wix/ambassador-checkout-server/types';
import { Member } from '@wix/ambassador-members-ng-api/types';
import {
  PaymentOption,
  ReservedPaymentOptionIds,
} from '../../../../../types/types';
import { PaymentTypeSelection } from './PaymentTypeSelection/PaymentTypeSelection';
import { useSettings } from '@wix/tpa-settings/react';
import { PaymentOptionSelection } from './PaymentOptionSelection/PaymentOptionSelection';
import { ServiceData, SlotService } from '../../../../../utils/state/types';
import { getServiceSlotIdentifier, mapToArray } from '../../../../../utils';
import { getPaymentOptions } from '../../../../../utils/payment';
import { SelectedPaymentOption } from '../../../../../types/ambassador/bookings/ambassador-bookings-v2-booking';

export type PaymentSelectionProps = {
  serviceData: ServiceData;
  memberDetails?: Member;
  numberOfParticipants: number;
  dateRegionalSettingsLocale: string;
  benefitsWithPlanInfo?: BenefitWithPlanInfo[];
  pricingPlanDetails?: PaidPlans;
  isPricingPlanInstalled: boolean;
  isBookingsOnEcom: boolean;
};

export const getPaymentSelection = (props: PaymentSelectionProps) => {
  const paymentSelection = renderPaymentSelection(props);

  const shouldShowSection = paymentSelection.length > 0;

  if (shouldShowSection) {
    return <>{paymentSelection}</>;
  }

  return null;
};

export const PaymentSelection: React.FC<PaymentSelectionProps> = (props) => {
  const paymentSelection = renderPaymentSelection(props);

  const shouldShowSection = paymentSelection.length > 0;

  if (shouldShowSection) {
    return <>{paymentSelection}</>;
  }

  return null;
};

const renderPaymentSelection = ({
  serviceData,
  dateRegionalSettingsLocale,
  numberOfParticipants,
  benefitsWithPlanInfo,
  isPricingPlanInstalled,
  pricingPlanDetails,
  isBookingsOnEcom,
  memberDetails,
}: PaymentSelectionProps) => {
  return mapToArray<SlotService>(serviceData.slotServices)
    .map((slotService) => {
      const paymentOptionSelection = RenderPaymentOptionSelection({
        slotService,
        dateRegionalSettingsLocale,
        numberOfParticipants,
        benefitsWithPlanInfo,
        isPricingPlanInstalled,
        pricingPlanDetails,
        memberDetails,
      });

      const paymentTypeSelection = renderPaymentTypeSelection({
        slotService,
        isBookingsOnEcom,
      });

      if (paymentOptionSelection || paymentTypeSelection) {
        return (
          <>
            {paymentOptionSelection}
            {paymentTypeSelection}
          </>
        );
      } else {
        return null;
      }
    })
    .filter((item) => item);
};

const RenderPaymentOptionSelection = ({
  slotService,
  dateRegionalSettingsLocale,
  numberOfParticipants,
  benefitsWithPlanInfo,
  isPricingPlanInstalled,
  pricingPlanDetails,
  memberDetails,
}: {
  slotService: SlotService;
  numberOfParticipants: number;
  dateRegionalSettingsLocale: string;
  benefitsWithPlanInfo?: BenefitWithPlanInfo[];
  pricingPlanDetails?: PaidPlans;
  isPricingPlanInstalled: boolean;
  memberDetails?: Member;
}) => {
  const settings = useSettings();
  const { t } = useTranslation();
  const {
    nestedSlot,
    selectedPaymentOption,
    service,
    memberships,
    dynamicPriceInfo,
  } = slotService;

  const dateAndTimeFormatter = useMemo(
    () => new DateTimeFormatter(dateRegionalSettingsLocale),
    [dateRegionalSettingsLocale],
  );

  const paymentOptions: PaymentOption[] = useMemo(
    () =>
      getPaymentOptions({
        servicePayment: service?.payment,
        benefitsWithPlanInfo,
        pricingPlanDetails,
        memberships,
        isPricingPlanInstalled,
        dateAndTimeFormatter,
        numberOfParticipants,
        dateRegionalSettingsLocale,
        isDynamicPreferenceType: dynamicPriceInfo?.isDynamicPreferenceType,
        t,
        settings,
      }),
    [memberDetails?.id, numberOfParticipants, dateRegionalSettingsLocale],
  );

  const shouldShowPaymentMethodSelection = paymentOptions.length > 1;

  if (!shouldShowPaymentMethodSelection) {
    return null;
  }

  return (
    <PaymentOptionSelection
      key={getServiceSlotIdentifier(nestedSlot)}
      paymentOptions={paymentOptions}
      slotService={slotService}
      benefitsWithPlanInfo={benefitsWithPlanInfo}
      isPricingPlanInstalled={isPricingPlanInstalled!}
      pricingPlanDetails={pricingPlanDetails}
      selectedPaymentOptionId={selectedPaymentOption.id}
      dateRegionalSettingsLocale={dateRegionalSettingsLocale!}
      numberOfParticipants={numberOfParticipants!}
    />
  );
};

const renderPaymentTypeSelection = ({
  slotService,
  isBookingsOnEcom,
}: Partial<PaymentSelectionProps> & { slotService: SlotService }) => {
  const { nestedSlot, selectedPaymentOption, service, selectedPaymentType } =
    slotService;
  const shouldShowPaymentTypeSelection =
    isBookingsOnEcom &&
    service.paymentTypes.includes(SelectedPaymentOption.OFFLINE) &&
    service.paymentTypes.includes(SelectedPaymentOption.ONLINE) &&
    selectedPaymentOption.id === ReservedPaymentOptionIds.SingleSession;

  if (!shouldShowPaymentTypeSelection) {
    return null;
  }

  const componentKey = getServiceSlotIdentifier(nestedSlot);

  return (
    <PaymentTypeSelection
      key={componentKey}
      service={slotService}
      selectedPaymentType={selectedPaymentType}
      paymentTypes={service.paymentTypes}
    />
  );
};
