import React from 'react';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import { classes, st } from './BookingDetails.st.css';
import { TagName } from '../../../../utils/tagName.const';
import { useSettings } from '@wix/tpa-settings/react';
import settingsParams from '../../settingsParams';
import { SectionTitle } from '../SectionTitle/SectionTitle';
import { getContent } from '../../../../utils/content/content';
import { ServiceData } from '../../../../utils/state/types';
import { FormSelectedSlot } from '../../../../types/formSelectedSlots';
import { getServiceSlotIdentifier } from '../../../../utils';
import SingleSlotDetails from './SingleSlotDetails/SingleSlotDetails';
import { ExperimentsConsts } from '../../../../consts/experiments';
import { Accordion } from 'wix-ui-tpa/cssVars';
import { BookingDetailsDataHook } from './dataHooks';

export interface BookingDetailsProps {
  dateRegionalSettingsLocale: string;
  serviceData: ServiceData;
  formSelectedSlot: FormSelectedSlot;
  numberOfParticipants?: number;
}

const SERVICE_DETAILS_TITLE = 'Service Details';

const BookingDetails: React.FC<BookingDetailsProps> = ({
  serviceData,
  formSelectedSlot,
  dateRegionalSettingsLocale,
  numberOfParticipants,
}) => {
  const { isMobile } = useEnvironment();
  const settings = useSettings();
  const { experiments } = useExperiments();

  const sectionTitle = getContent({
    settings,
    settingsParam: settingsParams.summarySectionTitle,
  });

  const isFormPaymentOptionNewDropdownEnabled = experiments.enabled(
    ExperimentsConsts.FormPaymentOptionNewDropdown,
  );

  if (isFormPaymentOptionNewDropdownEnabled || !serviceData.isSingleService) {
    return (
      <div className={st(classes.root, { isMobile })}>
        <Accordion
          initiallyExpanded={isMobile ? '' : SERVICE_DETAILS_TITLE}
          className={classes.accordion}
          data-hook={BookingDetailsDataHook.ACCORDION}
        >
          <Accordion.Item id={SERVICE_DETAILS_TITLE} title={sectionTitle}>
            {formSelectedSlot.nestedSlots.map((slot, index, slots) => {
              const slotService =
                serviceData.slotServices[getServiceSlotIdentifier(slot)];
              return (
                <div className={index < slots.length ? classes.item : ''}>
                  <SingleSlotDetails
                    key={getServiceSlotIdentifier(slot)}
                    slot={slot}
                    slotService={slotService}
                    dateRegionalSettingsLocale={dateRegionalSettingsLocale}
                    totalNumberOfParticipants={numberOfParticipants}
                  />
                </div>
              );
            })}
          </Accordion.Item>
        </Accordion>
      </div>
    );
  } else {
    return (
      <div className={st(classes.root, { isMobile })}>
        <SectionTitle label={sectionTitle} tagName={TagName.SecondaryHeading} />
        {formSelectedSlot.nestedSlots.map((slot) => {
          const slotService =
            serviceData.slotServices[getServiceSlotIdentifier(slot)];
          return (
            <SingleSlotDetails
              slot={slot}
              slotService={slotService}
              dateRegionalSettingsLocale={dateRegionalSettingsLocale}
            />
          );
        })}
      </div>
    );
  }
};

export default BookingDetails;
