import React from 'react';
import { TagName } from '../../../../utils/tagName.const';
import { PaymentSectionContainerHook } from './dataHooks';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import { classes, st } from './PaymentSelectionContainer.st.css';
import { BenefitWithPlanInfo } from '@wix/ambassador-pricing-plans-v2-benefit/types';
import { PaidPlans } from '@wix/ambassador-checkout-server/types';
import { Member } from '@wix/ambassador-members-ng-api/types';
import { useSettings } from '@wix/tpa-settings/react';
import { getContent } from '../../../../utils/content/content';
import settingsParams from '../../settingsParams';
import { SectionTitle } from '../SectionTitle/SectionTitle';
import { useFormComponentContext } from '../../Hooks/useFormComponentContext';
import { ServiceData } from '../../../../utils/state/types';
import { getMultiPaymentSelectionContainer } from './MultiPaymentSelectionContainer/MultiPaymentSelectionContainer';
import { getPaymentSelection } from './PaymentSelection/PaymentSelection';
import { ExperimentsConsts } from '../../../../consts/experiments';

export type PaymentSelectionContainerProps = {
  serviceData: ServiceData;
  memberDetails?: Member;
  numberOfParticipants: number;
  dateRegionalSettingsLocale: string;
  benefitsWithPlanInfo?: BenefitWithPlanInfo[];
  pricingPlanDetails?: PaidPlans;
  isPricingPlanInstalled: boolean;
  isBookingsOnEcom: boolean;
};

export const PaymentSelectionContainer: React.FC<
  PaymentSelectionContainerProps
> = (props) => {
  const { isMobile } = useEnvironment();
  const { isBookingsOnEcom } = useFormComponentContext();
  const { experiments } = useExperiments();
  const settings = useSettings();

  let componentToShow = null;
  if (
    !props.serviceData.isSingleService ||
    experiments.enabled(ExperimentsConsts.FormPaymentOptionNewDropdown)
  ) {
    componentToShow = getMultiPaymentSelectionContainer(props);
  } else {
    componentToShow = getPaymentSelection(props);
  }

  const settingsParamForTitle = isBookingsOnEcom
    ? settingsParams.selectPaymentTitle
    : settingsParams.selectPaymentMethodTitle;
  const title = getContent({
    settings,
    settingsParam: settingsParamForTitle,
  });

  return (
    componentToShow && (
      <fieldset
        className={st(classes.root, { isMobile })}
        data-hook={PaymentSectionContainerHook.Payment_Section}
      >
        <SectionTitle label={title} tagName={TagName.Legend} />
        {componentToShow}
      </fieldset>
    )
  );
};
