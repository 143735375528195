import React from 'react';
import { StaffMembersDataHooks } from './dataHooks';
import { TagName } from '../../../../utils/tagName.const';
import { StaffMember } from '@wix/bookings-uou-types';
import Text, { TextType } from '../../Text/Text';
import { TFunction, useTranslation } from '@wix/yoshi-flow-editor';

export interface StaffMembersProps {
  staffMembers: StaffMember[];
}

const StaffMembers: React.FC<StaffMembersProps> = ({ staffMembers }) => {
  const { t } = useTranslation();
  const staffMemberNames = getStaffMembers(staffMembers, t);

  return (
    <Text
      type={TextType.Secondary}
      data-hook={StaffMembersDataHooks.STAFF_MEMBER}
      tagName={TagName.Paragraph}
    >
      {staffMemberNames}
    </Text>
  );
};

const getStaffMembers = (staffMembers: StaffMember[], t: TFunction) => {
  if (staffMembers.length) {
    return staffMembers.map((staffMember) => staffMember.name).join(', ');
  }
  return t('app.bookings-form.appointment-details.first-available');
};

export default StaffMembers;
