import React, { useMemo } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { ServiceData, SlotServices } from '../../../../utils/state/types';
import { FormStatus } from '../../../../types/form-state';
import { BuyPlanButton } from './BuyPlanButton';
import { BookButton } from './BookButton';
import { AddCartButton } from './AddCartButton';
import {
  BookErrorType,
  CreateBookingErrorType,
  FormError,
} from '../../../../types/errors';
import {
  checkDifferentBuyPlans,
  getExceedLimitedPricePlans,
  getSelectedServicesForBuyPlan,
} from '../../../../utils/payment';
import {
  SectionMessage,
  SectionMessageType,
} from '../SectionMessage/SectionMessage';
import { classes } from './ButtonContainer.st.css';
import { getErrorByType } from '../../../../utils/errors/errors';
import { ButtonAdapterDataHooks } from './dataHooks';
import { CartFlow } from '../../../../types/types';

export interface ButtonContainerProps {
  serviceData: ServiceData;
  status: FormStatus;
  errors: FormError[];
  isCart?: boolean;
  isServiceInCart?: boolean;
}

interface AlertMessageProps {
  slotServices: SlotServices;
}

interface ErrorMessageProps {
  errors: FormError[];
}

const ButtonsContainer: React.FC<ButtonContainerProps> = ({
  serviceData,
  isCart,
  status,
  isServiceInCart,
  errors,
}) => {
  const slotServicesWithBuyPlans = getSelectedServicesForBuyPlan(
    serviceData.slotServices,
  );
  const exceedLimitedPricePlans = getExceedLimitedPricePlans(
    serviceData.slotServices,
  );
  let buttons;

  if (slotServicesWithBuyPlans.length || exceedLimitedPricePlans.length) {
    buttons = (
      <BuyPlanButton slotServices={serviceData.slotServices} status={status} />
    );
  } else if (isCart) {
    buttons = (
      <>
        <AddCartButton status={status} />
        {!isServiceInCart && (
          <BookButton
            slotServices={serviceData.slotServices}
            dataHook={ButtonAdapterDataHooks.CART_CHECKOUT_CTA}
            cartFlow={CartFlow.CHECKOUT}
            status={status}
            primary={false}
          />
        )}
      </>
    );
  } else {
    buttons = (
      <BookButton slotServices={serviceData.slotServices} status={status} />
    );
  }

  return (
    <>
      <RenderAlertMessage slotServices={serviceData.slotServices} />
      {buttons}
      <RenderErrorMessage errors={errors} />
    </>
  );
};

const RenderAlertMessage: React.FC<AlertMessageProps> = ({ slotServices }) => {
  const { t } = useTranslation();

  const isDifferentBuyPlan = checkDifferentBuyPlans(slotServices);
  const exceedPlans = getExceedLimitedPricePlans(slotServices);

  if (!isDifferentBuyPlan && !exceedPlans?.length) {
    return null;
  }

  let alertText = t('app.bookings-form.right-side-alert.too-many-plans');
  if (exceedPlans?.length) {
    const planNames = exceedPlans.map((plan) => plan.label).join(', ');
    alertText = t(
      'app.bookings-form.right-side-alert.not-enough-sessions-left-price-plan',
      { planNames: `'${planNames}'` },
    );
  }

  return (
    <div className={classes.root}>
      <SectionMessage
        type={SectionMessageType.Alert}
        text={alertText}
        withoutIcon={true}
      />
    </div>
  );
};

const RenderErrorMessage: React.FC<ErrorMessageProps> = ({ errors }) => {
  const { t } = useTranslation();

  const error = getErrorByType({
    errorType: BookErrorType,
    errors,
  });

  const getErrorMessage = useMemo(() => {
    if (error) {
      switch (error.errorType) {
        case CreateBookingErrorType.SESSION_CAPACITY_EXCEEDED:
        case CreateBookingErrorType.SCHEDULE_CAPACITY_EXCEEDED as any:
          return t('app.server-errors.not-enough-spots-left');
        default:
          return t('app.server-errors.slot-not-available');
      }
    }
    return '';
  }, [error]);

  return (
    <div className={classes.root}>
      <SectionMessage type={SectionMessageType.Error} text={getErrorMessage} />
    </div>
  );
};

export default ButtonsContainer;
